import React from 'react'
import styled from '@emotion/styled'
import { owlMix, buttonReset } from '../styles'

const Button = styled.button<{ spacing: number; padding: number }>`
  ${buttonReset}
  ${props => owlMix('top', props.spacing)}
  padding: ${props => props.padding}px;
`

const Line = styled.div<{
  width: number
  height: number
  i: number
  isActive: boolean
  yOffset?: number
}>`
  background-color: #000;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;

  ${props =>
    props.isActive &&
    props.i == 0 &&
    `
      transform: translateY(${props.yOffset}px) rotate(45deg);
  `}

  ${props =>
    props.isActive &&
    props.i == 1 &&
    `
      opacity: 0;
      transform: translateX(-${props.width / 2}px);
  `}

  ${props =>
    props.isActive &&
    props.i == 2 &&
    `
      transform: translateY(-${props.yOffset}px) rotate(-45deg);
  `}
`

type Props = {
  isActive: boolean
  onClick: Function

  padding: number
  lineWidth: number
  lineHeight: number
  lineSpacing: number
}

const HamburgerMenu: React.FC<Props> = ({
  lineWidth,
  padding,
  lineHeight,
  lineSpacing,
  isActive,
  onClick
}) => {
  // we have to move the line up and down to have the rotation center point at
  // the middle of the box. To do that, we calculate the y-center of the box:
  const height = lineSpacing * 2 + lineHeight * 3
  const yOffset = (height - lineHeight) / 2

  const clickHandler = e => {
    e.preventDefault()
    onClick()
  }

  return (
    <Button
      spacing={lineSpacing}
      onMouseDown={e => {
        // accessibility friendly solution for removing the focus when someone
        // clicks but still allowing for keyboard navigation:
        e.preventDefault()
      }}
      onClick={e => onClick()}
      padding={padding}
      aria-label="Toggle Navigation Menu"
    >
      <Line
        i={0}
        width={lineWidth}
        height={lineHeight}
        isActive={isActive}
        yOffset={yOffset}
      />
      <Line i={1} width={lineWidth} height={lineHeight} isActive={isActive} />
      <Line
        i={2}
        width={lineWidth}
        height={lineHeight}
        isActive={isActive}
        yOffset={yOffset}
      />
    </Button>
  )
}

export default HamburgerMenu
