import { useState, useRef, useEffect } from 'react'

export function useToggle(initialState = false) {
  const [state, setState] = useState(initialState)
  const toggle = () => setState(state => !state)

  return [state, toggle]
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
