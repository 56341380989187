import React from 'react'
import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'

const ButtonContainer = styled.button`
  transition: background-color 0.2s;
  ${tw`no-underline inline-block rounded`}

  &:disabled {
    ${tw`bg-grey-light`}
  }

  &:hover:enabled {
    ${tw`cursor-pointer bg-primary-dark`}
  }

  ${props =>
    props.variant === 'normal' &&
    tw`bg-primary text-white text-base py-2 px-4 border-none`};

  ${props =>
    props.variant === 'primary' &&
    tw`bg-primary text-white font-bold text-lg py-2 px-4 border-none`};

  ${props =>
    props.variant === 'transparent' &&
    css`
      ${tw`bg-none text-black font-bold text-base py-2 px-4 border-none`}

      &:hover:enabled {
        ${tw`bg-grey-lighter`}
      }
    `};

  ${props =>
    props.variant === 'secondary' &&
    css`
      ${tw`bg-secondary text-primary font-bold text-lg py-3 px-5 border-none shadow`};

      &:hover:enabled {
        ${tw`bg-secondary-light`};
      }
    `}
`

function Button({ variant, as, to, children, ...props }) {
  return (
    <ButtonContainer {...props} variant={variant} as={as} to={to}>
      {children}
    </ButtonContainer>
  )
}

export default Button
