import React from 'react'
import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { ContainerLg } from './container'
import { useStaticQuery, graphql } from 'gatsby'
import { owl } from '../styles'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { ReactComponent as SvgLogo } from '../components/icons/holzschutz24.svg'
import Button from './button'

const Root = styled.footer`
  ${tw`bg-primary py-10`};
`

const CompanyInfo = styled.div`
  ${owl(tw`md:ml-8 mt-8 lg:mt-'0`)}
  ${tw`md:flex items-end mb-8 lg:mb-0`};

  p:first-child {
    ${tw`mb-1`}
  }
`

const Text = styled.p`
  ${tw`text-white flex flex-col my-0 py-1`}
`

const Legal = styled(Text)`
  ${tw`text-xs lg:text-sm`}
`

const UnstyledAnchor = styled.a`
  ${tw`text-white py-1 inline-block mt-1`}
`

const Container = styled(ContainerLg)`
  ${tw`px-8 lg:flex items-end justify-between`};
`

function Divider() {
  return (
    <span
      css={css`
        ${tw`mx-2 lg:mx-4`}
      `}
    >
      -
    </span>
  )
}

const AdditionalFooterRoot = styled.div`
  ${tw`py-24 lg:py-32 bg-primary-light text-white`};
`

function Footer() {
  const { sanityCompanyInfo: info } = useStaticQuery(graphql`
    query FooterQuery {
      sanityCompanyInfo {
        email
        name
        street
        tel
        landlineTel
        zipCode
      }
    }
  `)

  return (
    <>
      <Root>
        <Container>
          <CompanyInfo>
            <Text>
              {info.name} <br /> {info.street} <br /> {info.zipCode}
            </Text>

            <Text>
              <span>
                <span>Mobil: </span>
                <UnstyledAnchor href={`tel:${info.tel}`}>
                  {info.tel}
                </UnstyledAnchor>
              </span>
              <span>
                <span>Festnetz: </span>
                <UnstyledAnchor href={`tel:${info.landlineTel}`}>
                  {info.landlineTel}
                </UnstyledAnchor>
              </span>
              <UnstyledAnchor href={`mailto:${info.email}`}>
                {info.email}
              </UnstyledAnchor>
            </Text>
          </CompanyInfo>

          <Legal>
            <span>
              &copy; {info.name}
              <Divider />
              Alle Rechte vorbehalten
              <Divider />
              <UnstyledAnchor as={Link} to="/impressum">
                Impressum
              </UnstyledAnchor>
            </span>
          </Legal>
        </Container>
      </Root>
    </>
  )
}

const Logo = styled(SvgLogo)`
  max-width: 320px;
`

function PreventionFooter() {
  return (
    <AdditionalFooterRoot>
      <Container>
        <div
          css={css`
            ${tw`flex flex-col lg:flex-row justify-between items-center w-full`}
          `}
        >
          <div
            css={css`
              ${tw`w-full lg:w-1/3 text-center`}
            `}
          >
            <Logo />
          </div>
          <p
            css={css`
              ${tw`max-w-md mt-12 lg:m-0 lg:ml-12`}
            `}
          >
            <b>
              Prävention ist die einfachste und günstigste Methode, Holz
              dauerhaft gegen Schäden durch Insekten- oder Pilzbefall zu
              schützen:
            </b>{' '}
            Das zu imprägnierende Material wird mit gelösten Holzschutzmitteln
            eingelassen, so dass ein dauerhafter Schutzwall gegen schädliche
            Einwirkungen gebildet wird. Solche Massnahmen können entweder der
            Bekämpfung eines bestehenden Befalls, dessen Vorbeugung oder beidem
            zugleich dienen.
            <br />
            <br />
            Unsere 30 jährige Erfahrung in der erfolgreichen Bekämpfung von
            Hausbock, Holzwurm, Holzameise oder anderen holzzerstörenden
            Insekten ist das Ergebnis unseres Fachwissens und stetiger
            Weiterbildung. Hausschwamm und Schimmel beseitigen wir ebenfalls
            professionell und nachhaltig.
          </p>
        </div>
      </Container>
    </AdditionalFooterRoot>
  )
}

const Heading = styled.h3`
  ${tw`text-xl text-white m-0 mb-6`};
`

function QualityGuaranteeFooter() {
  return (
    <AdditionalFooterRoot>
      <Container>
        <div
          css={css`
            ${tw`flex flex-col lg:flex-row justify-between items-center w-full`}
          `}
        >
          <div
            css={css`
              ${tw`w-64`};
            `}
          >
            <Logo />
          </div>

          <div
            css={css`
              ${tw`max-w-xs text-center mt-24 lg:mt-0 flex-auto flex-no-grow flex-no-shrink`};
            `}
          >
            <Heading>
              Ihre kostenlose Beratung mit Analyse vor Ort und unverbindlicher
              Offerte
            </Heading>
            <Button to="/kontakt" as={Link} variant="primary">
              Beratung anfordern
            </Button>
          </div>
        </div>
      </Container>
    </AdditionalFooterRoot>
  )
}

export default Footer
export { PreventionFooter, QualityGuaranteeFooter }
