import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { animated, useSpring } from 'react-spring/web.cjs'
import tw from 'tailwind.macro'
import { ContainerLg } from './container'
import Footer from './footer'
import Header from './header'

const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  html {
    overflow-y: scroll;
    ${tw`font-sans`};
  }

  html.wf-active {
    font-family: 'Source Sans Pro';
  }

  body {
    ${tw`m-0 leading-normal`}
  }
`

const Root = styled.div`
  ${tw`flex`};
`

const Container = styled.div`
  ${tw`flex flex-col w-full`};
  min-height: 100vh;
`
const Content = styled.div`
  ${tw`flex-grow`};
`

function Layout({ FooterComponent, pageTransition = true, children }) {
  const { sanityCompanyInfo: companyInfo } = useStaticQuery(graphql`
    query HeaderQuery {
      sanityCompanyInfo {
        logo {
          imageText
          image {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const animatedStyles = useSpring({
    from: { transform: 'translateY(-20px)', opacity: 0 },
    transform: 'translateY(0px)',
    opacity: 1
  })

  return (
    <>
      <Global styles={globalStyles} />
      <Root>
        <Container>
          <Content>
            <ContainerLg withPaddingX={false}>
              <Header logo={companyInfo.logo} />
            </ContainerLg>

            <div
              css={css`
                ${tw`mb-10`}
              `}
            >
              <animated.div style={pageTransition ? animatedStyles : {}}>
                {children}
              </animated.div>
            </div>
          </Content>

          {FooterComponent && <FooterComponent />}
          <Footer />
        </Container>
      </Root>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
