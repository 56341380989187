import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import tw from 'tailwind.macro'
import { useToggle } from '../hooks'
import { owl } from '../styles'
import Button from './button'
import { PaddingX } from './container'
import HamburgerMenu from './hamburger-menu'

const Root = styled(PaddingX)`
  ${tw`py-6 lg:py-10 flex justify-between flex-wrap items-center relative`};
`

const Nav = styled.div`
  ${owl(tw`lg:ml-10`)}
  ${tw`block flex-grow w-full z-10  lg:flex-no-grow lg:flex lg:items-center lg:w-auto`}
  ${props => !props.isMenuOpen && tw`hidden`}
  ${props =>
    props.isMenuOpen &&
    css`
      ${tw`absolute bg-white shadow pin-l p-6`}
      top: 100%;
    `}
`

const NavLinkWrapper = styled.div`
  ${owl(tw`mb-4 lg:mb-0 lg:ml-5`)}
`

const NavLink = styled(Link)`
  ${tw`text-primary no-underline`}
  ${tw`block mt-4 lg:inline-block lg:mt-0`}
`

const HiddenOnDesktop = styled.div`
  ${tw`block lg:hidden`};
`

function Header({ logo }) {
  const [isMenuOpen, toggleMenu] = useToggle(false)

  return (
    <Root as="nav">
      <Link to="/">
        <img src={logo.image.asset.url} alt={logo.imageText} width={100} />
      </Link>

      <HiddenOnDesktop>
        <HamburgerMenu
          isActive={isMenuOpen}
          onClick={toggleMenu}
          padding={10}
          lineWidth={30}
          lineHeight={3}
          lineSpacing={6}
        />
      </HiddenOnDesktop>

      <Nav isMenuOpen={isMenuOpen}>
        <NavLinkWrapper>
          <NavLink to="/">Start</NavLink>
          <NavLink to="/ueber-uns">Über Uns</NavLink>
          <NavLink to="/referenzen">Referenzen</NavLink>
          <NavLink to="/kontakt">Kontakt</NavLink>
        </NavLinkWrapper>

        <Button as={Link} to="/kontakt" variant="primary">
          Offerte einholen
        </Button>
      </Nav>
    </Root>
  )
}

export default Header
