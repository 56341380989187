import { getFluidGatsbyImage } from 'gatsby-source-sanity'

export function getSanityImage(image, fluidArgs = { maxWidth: 1920 }) {
  console.log(image.asset, {
    dataset: process.env.GATSBY_SANITY_DATASET,
    projectId: process.env.GATSBY_SANITY_PROJECT_ID
  })
  return {
    asset: {
      fluid: getFluidGatsbyImage(image.asset, fluidArgs, {
        dataset: process.env.GATSBY_SANITY_DATASET,
        projectId: process.env.GATSBY_SANITY_PROJECT_ID
      })
    }
  }
}
