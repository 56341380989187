import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { getSanityImage } from '../util'
import React from 'react'
import tw from 'tailwind.macro'
import Button from '../components/button'
import { ContainerSm } from '../components/container'
import { QualityGuaranteeFooter } from '../components/footer'
import Layout from '../components/layout'
import SanityContentRenderer from '../components/sanity-content-renderer'
import SEO from '../components/seo'
import { Slideshow } from '../components/slideshow'
import { owl } from '../styles'

const SlideshowWrapper = styled.div`
  ${tw`mb-6 lg:mb-10`};
`

function LandingPageTemplate({ pageContext: { page } }) {
  return (
    <Layout FooterComponent={QualityGuaranteeFooter}>
      <SEO title={page.suffix} description={page.description} />
      <SlideshowWrapper>
        <Slideshow
          slides={[
            {
              ...page._rawContentBlocks[0],
              image: getSanityImage(page._rawContentBlocks[0].image)
            }
          ]}
          slideFootnote={
            <div
              css={css`
                ${tw`pt-3`}
                ${owl(`ml-2`)}
              `}
            >
              <Button as={Link} to="/kontakt" variant="secondary">
                Beratung anfordern
              </Button>
            </div>
          }
        />
      </SlideshowWrapper>
      <ContainerSm>
        <SanityContentRenderer blocks={page._rawContentBlocks[1].content} />
      </ContainerSm>
    </Layout>
  )
}

export default LandingPageTemplate
