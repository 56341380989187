import { css } from '@emotion/core'
import styled from '@emotion/styled'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image/withIEPolyfill'
import React, { useState } from 'react'
import { animated, useTransition } from 'react-spring/web.cjs'
import useHover from 'react-use-hover'
import tw from 'tailwind.macro'
import { useInterval } from '../hooks'
import { ContainerLg } from './container'

type Slide = {
  image: { caption: string; asset: { fluid: any } }
  title: string
  description: object
  textPosition: 'bottomLeft' | 'center'
}

type SlideshowProps = {
  delayMs: number
  slides: Slide[]
  slideFootnote: any
}

const SlideshowWrapper = styled.div`
  height: 50vh;
  ${tw`relative overflow-hidden`};
`

const Slide = styled.div`
  ${tw`absolute pin-l pin-t w-full`};
`

const SlideImage = ({ fluid }) => (
  <Img
    fluid={fluid}
    style={{
      height: '50vh'
    }}
    imgStyle={{
      objectFit: 'cover'
    }}
  />
)

const SlideTitle = styled.h2`
  ${tw`max-w-md m-0 text-4xl`};
`

const SlideDescription = styled.div`
  ${tw`max-w-sm m-0`};

  > p {
    ${tw`m-0`};
  }
`

const SlideTextContainer = styled(ContainerLg)<{
  position: 'bottomLeft' | 'center'
}>`
  ${tw`absolute text-white w-full h-full flex flex-col py-6 lg:py-10`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props => props.position == 'bottomLeft' && tw`justify-end items-start`}
  ${props =>
    props.position == 'center' &&
    css`
      ${tw`justify-center items-center text-center`}
    `}
` as any

const GradientImage = styled.div`
  ${tw`relative`};

  &:after {
    ${tw`absolute pin-t pin-l w-full h-full`};
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
  }
`

export const Slideshow: React.FC<SlideshowProps> = ({
  slides,
  delayMs,
  slideFootnote
}) => {
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 })
  const [index, set] = useState(0)

  useInterval(() => {
    if (!isHovering) {
      set(state => (state + 1) % slides.length)
    }
  }, delayMs)

  const transitions = useTransition(index, p => p, {
    initial: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  })

  return (
    <SlideshowWrapper {...hoverProps}>
      {transitions.map(({ item, props, key }) => {
        const slide = slides[item]

        return (
          <animated.div style={props} key={key}>
            <Slide>
              <GradientImage>
                <SlideImage fluid={slide.image.asset.fluid} />
              </GradientImage>
              <SlideTextContainer
                withPadding={true}
                position={slide.textPosition}
              >
                <SlideTitle>{slide.title}</SlideTitle>
                {slide.description && (
                  <SlideDescription>
                    <BlockContent blocks={slide.description} />
                  </SlideDescription>
                )}

                {slideFootnote}
              </SlideTextContainer>
            </Slide>
          </animated.div>
        )
      })}
    </SlideshowWrapper>
  )
}

Slideshow.defaultProps = {
  delayMs: 5000
}
