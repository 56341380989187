import { css } from '@emotion/core'

/**
 * Lobotomized owl selector - Control children spacing from outside
 *
 * https://alistapart.com/article/axiomatic-css-and-lobotomized-owls
 */
export const owl = size => css`
  & > * + * {
    ${size}
  }
`

export const owlMix = (direction, size) => `
  & > * + * {
    margin-${direction}: ${size}px
  }
`

export const buttonReset = `
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
`
