import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'

const defaultPadding = css`
  ${tw`px-6`}
`

export const ContainerLg = styled.div<{ withPaddingX?: boolean }>`
  ${tw`m-auto max-w-3xl`};
  ${props => props.withPaddingX && defaultPadding}
`

ContainerLg.defaultProps = {
  withPaddingX: true
}

export const PaddingX = styled.div`
  ${defaultPadding}
`

export const ContainerSm = styled.div<{ withPaddingX?: boolean }>`
  ${tw`m-auto max-w-lg`};
  ${props => props.withPaddingX && defaultPadding}
`

ContainerSm.defaultProps = {
  withPaddingX: true
}
